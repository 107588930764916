import React from 'react';
import {
  ownerFieldsBundle,
  formDivDivider,
  baseFormFields,
  baseNeteviaFields,
  baseRepayFields,
  addressFormFields,
  toggleFieldOptions,
  customValidations
} from './_boardingToolFields';
import {
  getFormattedRelationship,
  sortData,
  ignoreCase,
  isBool,
  isEmpty,
  camelToTitle,
  fileTagValues
} from './_helpers';
import globalFormProps from './_formHelpers';
import { feesTabFormFieldsPriority, priorityTabFormFields } from './_crabPriorityHelpers';
import { isPriorityTsysBank } from './_templateHelpers';

export const crabTaskPendFieldsPost = {
  // POST /v1/application/task/pend
  description: {
    id: 'description',
    label: 'Description',
    type: 'textarea',
    fieldType: 'input',
    inputStyle: {
      overflow: 'auto',
      height: 'auto',
      resize: 'vertical',
      minHeight: '65px'
    },
    wrapperStyle: { margin: 'unset', flex: '100%' }
  },
  pendStatus: {
    id: 'pendStatus',
    label: 'Status',
    type: 'text',
    fieldType: 'dropdown',
    list: [
      { title: 'Initial', value: 'initial', agentCanEdit: true },
      { title: 'Repended', value: 'repended', agentCanEdit: true },
      { title: 'Addressed', value: 'addressed', partnerCanEdit: true },
      { title: 'Resolved', value: 'resolved', agentCanEdit: true },
      { title: 'Needs Help', value: 'needs_help', partnerCanEdit: true },
      { title: 'Revisit', value: 'revisit', agentCanEdit: true }
    ],
    wrapperStyle: { margin: 'unset', marginLeft: '-1px' },
    displaySearch: true,
    formField: true
  },
  requiresNewSignature: {
    id: 'requiresNewSignature',
    name: 'requiresNewSignature',
    label: 'Requires New Signature',
    type: 'radio',
    fieldType: 'radio',
    fields: [
      { label: 'Yes', value: 'yes' },
      { label: 'No', value: 'no' }
    ],
    wrapperStyle: { margin: 'unset' },
    required: false
  }
};

export const crabTaskPendFieldsPut = {
  // PUT /v1/application/task/pend
  description: {
    ...crabTaskPendFieldsPost.description,
    type: 'textarea',
    required: true
  },
  requiresNewSignature: {
    name: 'requiresNewSignature',
    label: 'This Pend will require a new signature',
    id: 'newSignature',
    // id is a different name than expected value because this shows on a modal with a form field
    // with the exact same ID name, causing some issues when using the checkbox as a result
    type: 'mini',
    height: 'auto',
    fieldType: 'checkbox',
    wrapperStyle: { margin: 'unset', marginTop: '5px' },
    required: true
  }
};

export const crabTaskPendFieldsInfo = {
  // GET /v1/application/task/pend
  negativeActionList: {
    id: 'negativeActionList',
    label: 'Negative Actions',
    type: 'textarea',
    fieldType: 'input',
    required: false,
    wrapperStyle: { margin: '0 0 -1px', flex: '100%' }
  },
  createdBy: {
    ...globalFormProps.input,
    id: 'createdBy',
    label: 'Created By',
    type: 'text',
    fieldType: 'input',
    required: false,
    wrapperStyle: { margin: 'unset', flex: '33%' }
  },
  created: {
    ...globalFormProps.timestamp,
    id: 'created',
    label: 'Created',
    type: 'date',
    fieldType: 'input',
    required: false,
    wrapperStyle: {
      margin: 'unset',
      marginLeft: '-1px',
      flex: '33%'
    },
    childStyles: { time: { fontSize: '1.2rem' } }
  },
  closed: {
    ...globalFormProps.timestamp,
    id: 'closed',
    label: 'Closed',
    type: 'date',
    fieldType: 'input',
    required: false,
    wrapperStyle: {
      margin: 'unset',
      marginLeft: '-1px',
      flex: '33%'
    },
    childStyles: { time: { fontSize: '1.2rem' } }
  }
};

export const crabExternalCommunicationFields = {
  description: {
    id: 'description',
    label: 'Message',
    type: 'textarea',
    fieldType: 'input'
  }
};

export const crabAppStatusList = [
  // uncompleted:
  {
    title: 'Draft',
    value: 'draft',
    partnerAppStatusBarTitle: 'New Apps',
    isPartnerStatus: true,
    employeeCanAbandonApp: true
  },
  {
    title: 'Waiting On Initial Signature',
    value: 'waiting_on_initial_signature',
    partnerAppStatusBarTitle: 'Awaiting Signature',
    isPartnerStatus: true,
    employeeCanAbandonApp: true
  },
  {
    title: 'Waiting On Ops',
    value: 'waiting_on_ops',
    partnerAppStatusBarTitle: 'Under Review',
    isOpsStatusFilter: true,
    employeeCanAbandonApp: true
  },
  {
    title: 'Waiting On Partner',
    value: 'waiting_on_partner',
    partnerAppStatusBarTitle: 'Pended Apps',
    isPartnerStatus: true,
    employeeCanAbandonApp: true
  },
  {
    title: 'Waiting On Credit',
    value: 'waiting_on_credit',
    partnerAppStatusBarTitle: 'Under Review',
    isCreditStatusFilter: true,
    employeeCanAbandonApp: true
  },
  {
    title: 'Waiting On App Review',
    value: 'waiting_on_app_review',
    partnerAppStatusBarTitle: 'Under Review',
    isAppReviewStatusFilter: true,
    employeeCanAbandonApp: true
  },
  {
    title: 'Waiting On Bank',
    value: 'waiting_on_bank',
    partnerAppStatusBarTitle: 'Under Review',
    isAppReviewStatusFilter: true,
    isOpsStatusFilter: true,
    isCreditStatusFilter: true,
    employeeCanAbandonApp: true
  },
  {
    title: 'Waiting On Bank Allotment',
    value: 'waiting_on_bank_allotment',
    partnerAppStatusBarTitle: 'Under Review',
    isAppReviewStatusFilter: true,
    isOpsStatusFilter: true,
    isCreditStatusFilter: true,
    employeeCanAbandonApp: true
  },
  {
    title: 'Waiting On Signature Post Pends',
    value: 'waiting_on_signature_post_pends',
    partnerAppStatusBarTitle: 'Awaiting Signature',
    isPartnerStatus: true,
    employeeCanAbandonApp: true
  },
  {
    title: 'Boarding',
    value: 'boarding',
    partnerAppStatusBarTitle: 'Boarding'
  },
  {
    title: 'Pended By Bank',
    value: 'pended_by_bank',
    partnerAppStatusBarTitle: 'Under Review',
    isAppReviewStatusFilter: true,
    isOpsStatusFilter: true,
    employeeCanAbandonApp: true
  },
  {
    title: 'Approved (Waiting On Bank)',
    value: 'approved_waiting_on_bank',
    partnerAppStatusBarTitle: '', // not applicable in app status bar
    isAppReviewStatusFilter: true,
    isCreditStatusFilter: true,
    // Considered "approved" but still shown in user's "open" apps bucket,
    // with all app actions (buttons) disabled.
    isApprovedAndOpen: true
  },
  {
    title: 'Abandoned',
    value: 'abandoned',
    partnerAppStatusBarTitle: '', // not applicable in app status bar
    isCompleted: true
  },
  // completed:
  {
    title: 'Approved',
    value: 'approved',
    partnerAppStatusBarTitle: '', // not applicable in app status bar
    isCompleted: true
  },
  {
    title: 'Withdrawn',
    value: 'withdrawn',
    partnerAppStatusBarTitle: '', // not applicable in app status bar
    isCompleted: true,
    canReopen: true
  },
  {
    title: 'Declined',
    value: 'declined',
    partnerAppStatusBarTitle: '', // not applicable in app status bar
    isCompleted: true,
    canReopen: true
  },
  {
    title: 'Boarded to Processor',
    value: 'api_boarded_to_processor',
    partnerAppStatusBarTitle: 'Boarding',
    canReopen: true,
    isAppReviewStatusFilter: true
  }
  // for new statuses please also add to
  // packages/web/src/data/report/templates/applicationStatusTemplate.js
];

export const crabApplicationFieldsPost = {
  // POST /v1/application
  description: {
    id: 'description',
    label: 'Description',
    type: 'textarea',
    fieldType: 'input'
  },
  declineReason: {
    componentType: 'combobox',
    props: {
      formField: true,
      required: true,
      fieldType: 'combobox',
      displaySearch: true,
      id: 'declineReason',
      label: 'Decline Reason',
      type: 'text',
      list: [
        { title: 'Prohibited Vertical', value: 'prohibited_vertical' },
        { title: 'Illicit Activity', value: 'illicit_activity' },
        { title: 'Match', value: 'match' },
        {
          title: 'Existing Account With  Negative History',
          value: 'existing_account_with_negative_history'
        },
        { title: 'OFAC', value: 'ofac' },
        { title: 'Excessive Risk Profile', value: 'excessive_risk_profile' },
        { title: 'Derogatory Credit', value: 'derogatory_credit' },
        { title: 'Underage Business Owner', value: 'underage_business_owner' }
      ],
      controls: {
        derogatory_credit: [
          {
            componentType: 'input',
            props: {
              id: 'creditScore',
              label: 'Credit Score',
              type: 'number',
              fieldType: 'input',
              required: true,
              min: 0
            }
          },
          {
            componentType: 'checkboxList',
            props: {
              ...globalFormProps.checkboxList,
              id: 'reasons',
              label: 'Reasons of Adverse Action',
              fieldType: 'checkboxList',
              required: true,
              noBoxBorder: true,
              // isCheckboxList: true, // if BE value is in the format that FE already expects
              containerStyle: {
                marginTop: '10px',
                flex: '100%',
                width: '100%'
              },
              checkboxStyle: {
                type: 'mini',
                wrapperStyle: {
                  width: 'fit-content'
                }
              },
              wrapperStyle: {
                border: '1px solid lightgrey',
                fontSize: '0.9em',
                lineHeight: '1.8em',
                borderRadius: 'var(--radius-main)',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                flexWrap: 'wrap',
                gap: '5px'
              },
              innerWrapperStyle: {
                border: 'none',
                padding: '0.167em 0.5em 0.25em 0.5em'
              },
              list: [
                // column one of the pdf
                {
                  title: 'Credit application incomplete',
                  value: 'credit_application_incomplete',
                  checked: false
                },
                {
                  title: 'Insufficient number of credit references provided',
                  value: 'insufficient_number_of_credit_references_provided',
                  checked: false
                },
                {
                  title: 'Unacceptable type of credit references provided',
                  value: 'unacceptable_type_of_credit_references_provided',
                  checked: false
                },
                {
                  title: 'Unable to verify credit references',
                  value: 'unable_to_verify_credit_references',
                  checked: false
                },
                {
                  title: 'Temporary or irregular employment',
                  value: 'temporary_or_irregular_employment',
                  checked: false
                },
                {
                  title: 'Unable to verify employment',
                  value: 'unable_to_verify_employment',
                  checked: false
                },
                { title: 'Length of employment', value: 'length_of_employment', checked: false },
                {
                  title: 'Income insufficient for amount of credit requested',
                  value: 'income_insufficient_for_amount_of_credit_requested',
                  checked: false
                },
                // column two of the pdf
                {
                  title: 'Excessive obligations in relation to income',
                  value: 'excessive_obligations_in_relation_to_income',
                  checked: false
                },
                {
                  title: 'Unable to verify income',
                  value: 'unable_to_verify_income',
                  checked: false
                },
                { title: 'Length of residence', value: 'length_of_residence', checked: false },
                { title: 'Temporary residence', value: 'temporary_residence', checked: false },
                {
                  title: 'Unable to verify residence',
                  value: 'unable_to_verify_residence',
                  checked: false
                },
                { title: 'No credit file', value: 'no_credit_file', checked: false },
                {
                  title: 'Limited credit experience',
                  value: 'limited_credit_experience',
                  checked: false
                },
                {
                  title: 'Poor credit performance with us',
                  value: 'poor_credit_performance_with_us',
                  checked: false
                },
                {
                  title: 'Delinquent past or present credit obligations with others',
                  value: 'delinquent_past_or_present_credit_obligations_with_others',
                  checked: false
                },
                // column three of the pdf
                {
                  title: 'Collection action or judgment',
                  value: 'collection_action_or_judgment',
                  checked: false
                },
                {
                  title: 'Garnishment or attachment',
                  value: 'garnishment_or_attachment',
                  checked: false
                },
                {
                  title: 'Foreclosure or repossession',
                  value: 'foreclosure_or_repossession',
                  checked: false
                },
                { title: 'Bankruptcy', value: 'bankruptcy', checked: false },
                {
                  title: 'Number of recent inquiries on credit bureau report',
                  value: 'number_of_recent_inquiries_on_credit_bureau_report',
                  checked: false
                },
                {
                  title: 'Value or type of collateral not sufficient',
                  value: 'value_or_type_of_collateral_not_sufficient',
                  checked: false
                },
                { title: 'Other, specify', value: 'otherSpecify', checked: false }
              ],
              controls: {
                otherSpecify: [
                  {
                    componentType: 'input',
                    props: {
                      id: 'otherReason',
                      label: 'Other Reason',
                      type: 'text',
                      fieldType: 'input',
                      required: true
                    }
                  }
                ]
              }
            }
          }
        ]
      }
    }
  }
};
export const crabFileTagsPost = {
  // POST /v1/file/tags
  fileName: {
    id: 'fileName',
    label: 'File Name',
    type: 'text',
    fieldType: 'input'
  },
  tagList: {
    id: 'tagList',
    label: 'Tags',
    type: 'text',
    fieldType: 'dropdown',
    list: fileTagValues,
    formField: true,
    displaySearch: true,
    editable: true,
    isMulti: true,
    required: false
  }
};

export const crabMasterTaskList = [
  {
    // TASK - ONLY CREDIT
    title: 'KYB (Know Your Business)',
    value: 'kyb_know_your_business_credit',
    isCreditTask: true
  },
  {
    // TASK - ONLY OPS
    title: 'KYB (Know Your Business)',
    value: 'kyb_know_your_business_ops',
    isOpsTask: true
  },
  {
    // TASK - APP_REVIEW
    title: 'KYB (Know Your Business)',
    value: 'kyb_know_your_business',
    isAppReviewTask: true
  },
  {
    // TASK - ONLY CREDIT
    title: 'KYC (Know Your Customer)',
    value: 'kyc_know_your_customer_credit',
    isCreditTask: true
  },
  {
    // TASK - ONLY OPS
    title: 'KYC (Know Your Customer)',
    value: 'kyc_know_your_customer_ops',
    isOpsTask: true
  },
  {
    // TASK - APP_REVIEW
    title: 'KYC (Know Your Customer)',
    value: 'kyc_know_your_customer',
    isAppReviewTask: true
  },
  {
    // TASK - ONLY CREDIT
    title: 'Banking Validation',
    value: 'banking_validation_credit',
    isCreditTask: true
  },
  {
    // TASK - ONLY OPS
    title: 'Banking Validation',
    value: 'banking_validation_ops',
    isOpsTask: true
  },
  {
    // TASK - APP_REVIEW
    title: 'Banking Validation',
    value: 'banking_validation',
    isAppReviewTask: true
  },
  {
    // TASK - APP_REVIEW & CREDIT
    title: 'MATCH',
    value: 'match',
    hasGetCall: true,
    isCreditTask: true,
    isAppReviewTask: true
  },
  {
    // TASK - APP_REVIEW & CREDIT
    title: 'Owner Credit Bureau Data',
    value: 'owner_credit_bureau_data',
    isCreditTask: true,
    isAppReviewTask: true
  },
  {
    // TASK - APP_REVIEW & CREDIT
    title: 'DBA Credit Bureau Data',
    value: 'dba_credit_bureau_data',
    isCreditTask: true,
    isAppReviewTask: true
  },
  {
    // TASK - APP_REVIEW & CREDIT
    title: 'OFAC',
    value: 'ofac',
    isCreditTask: true,
    isAppReviewTask: true
  },
  {
    // TASK - ONLY CREDIT
    title: 'MPA Validation',
    value: 'mpa_validation_credit',
    isCreditTask: true
  },
  {
    // TASK - ONLY OPS
    title: 'MPA Validation',
    value: 'mpa_validation_ops',
    isOpsTask: true
  },
  {
    // TASK - APP_REVIEW
    title: 'MPA Validation',
    value: 'mpa_validation',
    isAppReviewTask: true
  },
  {
    // TASK - ONLY CREDIT
    title: 'MPA Post New Signature Verification',
    value: 'mpa_post_new_signature_verification_credit',
    isCreditTask: true
  },
  {
    // TASK - ONLY OPS
    title: 'MPA Post New Signature Verification',
    value: 'mpa_post_new_signature_verification_ops',
    isOpsTask: true
  },
  {
    // TASK - APP_REVIEW
    title: 'MPA Post New Signature Verification',
    value: 'mpa_post_new_signature_verification',
    isAppReviewTask: true
  },
  {
    // TASK - ONLY CREDIT
    title: 'Additional Document Review',
    value: 'additional_document_review_credit',
    isCreditTask: true
  },
  {
    // TASK - ONLY OPS
    title: 'Additional Document Review',
    value: 'additional_document_review_ops',
    isOpsTask: true
  },
  {
    // TASK - APP_REVIEW
    title: 'Additional Document Review',
    value: 'additional_document_review',
    isAppReviewTask: true
  },
  {
    // TASK - ONLY CREDIT
    title: 'Related Persons',
    value: 'related_person_credit',
    hasGetCall: true,
    isCreditTask: true
  },
  {
    // TASK - ONLY OPS
    title: 'Related Persons',
    value: 'related_person_ops',
    hasGetCall: true,
    isOpsTask: true
  },
  {
    // TASK - APP_REVIEW
    title: 'Related Persons',
    value: 'related_person',
    hasGetCall: true,
    isAppReviewTask: true
  },
  {
    // TASK - APP_REVIEW & CREDIT
    title: 'Risk Score',
    value: 'risk_score',
    isCreditTask: true,
    isAppReviewTask: true
  },
  {
    // TASK - ONLY CREDIT
    title: 'Corvia Documents Review',
    value: 'corvia_documents_review_credit',
    isCreditTask: true
  },
  {
    // TASK - ONLY OPS
    title: 'Corvia Documents Review',
    value: 'corvia_documents_review_ops',
    isOpsTask: true
  },
  {
    // TASK - APP_REVIEW
    title: 'Corvia Documents Review',
    value: 'corvia_documents_review',
    isAppReviewTask: true
  },
  {
    // TASK - ONLY CREDIT
    title: 'Due Diligence Review',
    value: 'due_diligence_review_credit',
    isCreditTask: true
  },
  {
    // TASK - ONLY OPS
    title: 'Due Diligence Review',
    value: 'due_diligence_review_ops',
    isOpsTask: true
  },
  {
    // TASK - APP_REVIEW
    title: 'Due Diligence Review',
    value: 'due_diligence_review',
    isAppReviewTask: true
  },
  {
    // TASK - ONLY CREDIT
    title: 'Negative Option Billing Requirements',
    value: 'negative_option_billing_requirements_credit',
    isCreditTask: true
  },
  {
    // TASK - ONLY OPS
    title: 'Negative Option Billing Requirements',
    value: 'negative_option_billing_requirements_ops',
    isOpsTask: true
  },
  {
    // TASK - APP_REVIEW
    title: 'Negative Option Billing Requirements',
    value: 'negative_option_billing_requirements',
    isAppReviewTask: true
  },
  {
    // TASK - ONLY CREDIT
    title: 'Business Financial Review',
    value: 'business_financial_review_credit',
    isCreditTask: true
  },
  {
    // TASK - APP_REVIEW
    title: 'Business Financial Review',
    value: 'business_financial_review',
    isAppReviewTask: true
  },
  {
    // TASK - ONLY CREDIT
    title: 'Risk Exposure',
    value: 'risk_exposure_credit',
    hasGetCall: true,
    isCreditTask: true
  },
  {
    // TASK - APP_REVIEW
    title: 'Risk Exposure',
    value: 'risk_exposure',
    hasGetCall: true,
    isAppReviewTask: true
  },
  {
    // TASK - APP_REVIEW & OPS
    title: 'Whois Review',
    value: 'whois_review',
    hasGetCall: true,
    isOpsTask: true,
    isAppReviewTask: true
  },
  {
    // TASK - APP_REVIEW & OPS
    title: 'Website HTML Review',
    value: 'website_html_review',
    hasGetCall: true,
    isOpsTask: true,
    isAppReviewTask: true
  },
  {
    // TASK - APP_REVIEW
    title: 'Prohibited Entities',
    value: 'prohibited_entities',
    hasGetCall: true,
    isAppReviewTask: true
  },
  {
    // TASK - APP_REVIEW
    title: 'Illicit Activity Review',
    value: 'illicit_activity_review',
    hasGetCall: true,
    isAppReviewTask: true
  },
  {
    // TASK - APP_REVIEW
    title: 'Rule Review',
    value: 'gds',
    hasGetCall: true,
    isAppReviewTask: true
  }
];

const repayGeneralFormFieldsCrab = (options) => {
  const { crabConfigurationOptions, riskLevel } = options || {};
  const { suppressStatementsDefault, merchantChargebackNotificationEmail } =
    crabConfigurationOptions || {};
  return {
    generalSection: {
      id: 'generalSection',
      chargebackNotificationEmailAddress: {
        ...baseRepayFields.chargebackNotificationEmailAddress,
        ...(!isEmpty(merchantChargebackNotificationEmail) && {
          initialValue: merchantChargebackNotificationEmail
        })
      },
      statementDistributionMethod: baseRepayFields.statementDistributionMethod,
      statementDestination: baseRepayFields.statementDestination,
      suppressStatement: {
        ...baseRepayFields.suppressStatement,
        ...(isBool(suppressStatementsDefault) && {
          initialValue: suppressStatementsDefault
        })
      },
      combinedAch: baseRepayFields.combinedAch,
      discountType: baseRepayFields.discountType(riskLevel),
      netAch: baseRepayFields.netAch(riskLevel),
      terminalName: baseRepayFields.terminalName,
      serviceLevel: baseRepayFields.serviceLevel(riskLevel)
    }
  };
};

const paymentMethodsAcceptedFieldsCrab = {
  paymentMethodsAcceptedSection: {
    id: 'paymentMethodsAcceptedSection',
    debitCardsOnly: {
      ...baseFormFields.debitCardsOnly,
      fields: [
        { label: 'Yes', value: 'yes' },
        { label: 'No', value: 'no' }
      ],
      controllerStateOverrides: {
        yes: {
          // on debitCardsOnly === 'yes', reset state for these fields
          // on 'yes', these are hidden/not applicable, reset value & isValid property
          paymentCardsAccepted: [],
          paymentCardsAcceptedIsValid: true,
          amexNumber: '',
          amexNumberIsValid: true,

          // on 'yes', `amexRetainedESA` must be defaulted to 'no':
          amexRetainedESA: 'no',
          amexRetainedESAIsValid: true
        },
        no: {
          // on debitCardsOnly === 'no', these are hidden/not applicable,
          // reset value & isValid property
          paymentCardsAccepted: [],
          paymentCardsAcceptedIsValid: false,
          amexNumber: '', // not visible until `amexOptBlue` is checked
          amexNumberIsValid: true,

          // default `amexRetainedESA` to 'no' for consistency:
          amexRetainedESA: 'no',
          amexRetainedESAIsValid: true
        }
      },
      controls: {
        yes: {
          debitCardsOnlyWarningDiv: baseFormFields.debitCardsOnlyWarningDiv
        },
        no: {
          paymentCardsAccepted: baseFormFields.paymentCardsAccepted,
          amexRetainedESA: {
            ...baseFormFields.amexRetainedESA,
            fields: [
              { label: 'Yes', value: 'yes' },
              { label: 'No', value: 'no' }
            ],
            wrapperStyle: { flex: '100%' }
          }
        }
      }
    },
    ...formDivDivider(),
    electronicBenefitsTransfer: {
      ...baseFormFields.electronicBenefitsTransfer,
      wrapperStyle: { flex: '100%' },
      controls: {
        yes: {
          ebtTextDiv: baseFormFields.ebtTextDiv,
          fnsEbtNumber: baseFormFields.fnsEbtNumber
        }
      }
    }
  }
};

const ratesAndFeesSharedFields = {
  radio: {
    type: 'radio',
    fieldType: 'radio',
    fields: [
      { label: 'Yes', value: 'yes' },
      { label: 'No', value: 'no' }
    ]
  },
  rate: {
    type: 'percent',
    suffixType: 'basisPoints',
    suffix: '%',
    fieldType: 'input',
    isRatio: true,
    wrapperStyle: {
      flex: '50%'
    }
  },
  rateFivePrecision: {
    type: 'percentFivePrecision',
    suffix: '%',
    suffixType: 'basisPoints',
    fieldType: 'input',
    isRatio: true,
    wrapperStyle: {
      flex: '50%'
    }
  },
  authorization: {
    // Maybe is $ field (or opposite)
    type: 'price',
    prefix: '$',
    priceToNumber: true,
    fieldType: 'input',
    wrapperStyle: {
      flex: '50%'
    }
  }
};

export const businessTabFormFieldsCrab = {
  // --------------------------- BUSINESS TAB --------------------------- //
  businessInformationSection: {
    id: 'businessInformationSection',
    dbaName: baseFormFields.dbaName,
    descriptor: baseFormFields.descriptor,
    taxpayerIdentificationNumber: {
      ...baseFormFields.taxpayerIdentificationNumber,
      errorMessage: 'Must be 9 digits' // some LR merchants have EIN
    },
    establishedYear: baseFormFields.establishedYear,
    telephoneNumber: baseFormFields.telephoneNumber,
    faxNumber: baseFormFields.faxNumber,
    contactFullName: {
      ...baseFormFields.contactFullName,
      label: 'Contact - Full Name'
    },
    contactEmailAddress: {
      ...baseFormFields.contactEmailAddress,
      label: 'Contact - Email Address'
    },
    merchantWebsite: {
      ...baseFormFields.merchantWebsite,
      required: false
    },
    organizationalStructure: baseFormFields.organizationalStructure,
    publicCompany: {
      ...baseFormFields.publicCompany,
      controls: {
        yes: {
          stockSymbol: baseFormFields.stockSymbol,
          market: baseFormFields.market
        }
      }
    },
    typeOfProductsOrServicesSold: baseFormFields.typeOfProductsOrServicesSold,
    preferredMccCode: baseFormFields.preferredMccCode
  },
  businessStreetAddressSection: {
    // subSection of businessInformation
    id: 'businessStreetAddressSection',
    merchantBusinessAddressAutocomplete: {
      id: 'merchantBusinessAddressAutocomplete',
      fieldType: 'addressAutocomplete',
      isAddressAutocomplete: true,
      required: true,
      baseAddressFields: {
        addressLine1: {
          ...addressFormFields.addressLine1,
          label: 'Business Street Address',
          required: true
        },
        addressLine2: { ...addressFormFields.addressLine2, required: false },
        city: { ...baseFormFields.city, required: true },
        state: {
          ...baseFormFields.state,
          fieldType: 'combobox',
          useBlockForm: true,
          required: true
        },
        postalCode: { ...baseFormFields.zip, id: 'postalCode', required: true },
        country: {
          ...addressFormFields.countryComboBox,
          id: 'country',
          useBlockForm: true,
          required: true
        }
      }
    }
  },
  billingAddressSection: {
    // subSection of businessInformation
    id: 'billingAddressSection',
    billingAddressSameAs: {
      ...baseFormFields.billingAddressSameAs,
      fields: [
        { label: 'Yes', value: 'yes' },
        { label: 'No', value: 'no' }
      ],
      controllerStateOverrides: {
        yes: {
          // When same as business address, the billing fields are ignored in the transform
          // but set it anyway so FormAssistant marks the field as valid
          merchantBillingAddressAutocomplete: {},
          merchantBillingAddressAutocompleteIsValid: true
        },
        no: {
          // reset billing address fields
          merchantBillingAddressAutocomplete: {
            country: 'US' // set default value
          },
          merchantBillingAddressAutocompleteIsValid: false
        }
      },
      controls: {
        no: {
          merchantBillingAddressAutocomplete: {
            id: 'merchantBillingAddressAutocomplete',
            fieldType: 'addressAutocomplete',
            isAddressAutocomplete: true,
            required: true,
            baseAddressFields: {
              addressLine1: {
                ...addressFormFields.addressLine1,
                label: 'Billing Street Address',
                required: true
              },
              addressLine2: { ...addressFormFields.addressLine2, required: false },
              city: { ...baseFormFields.city, required: true },
              state: {
                ...baseFormFields.state,
                fieldType: 'combobox',
                useBlockForm: true,
                required: true
              },
              postalCode: { ...baseFormFields.zip, id: 'postalCode', required: true },
              country: {
                ...addressFormFields.countryComboBox,
                id: 'country',
                useBlockForm: true,
                required: true
              }
            }
          }
        }
      }
    }
  },
  paymentProcessingAndSalesSection: {
    id: 'paymentProcessingAndSalesSection',
    monthlyTotalProcessingVolume: baseFormFields.monthlyTotalProcessingVolume,
    monthlyAmexProcessingVolume: baseFormFields.monthlyAmexProcessingVolume,
    averageTransactionAmount: baseFormFields.averageTransactionAmount,
    highestTransactionAmount: baseFormFields.highestTransactionAmount,
    seasonalBusiness: baseFormFields.seasonalBusiness
  },
  orderChannelSection: {
    // subSection of paymentProcessingAndSalesSection
    id: 'orderChannelSection',
    emvOrCardPresentSwiped: baseFormFields.emvOrCardPresentSwiped,
    telephoneOrder: baseFormFields.telephoneOrder,
    eCommerce: baseFormFields.eCommerce
  },
  businessAccountNumberSection: {
    id: 'businessAccountNumberSection',
    routingNumber: { ...baseFormFields.routingNumber, label: 'ABA Routing Number' },
    accountNumber: baseFormFields.accountNumber
  },
  achInformationAndFundingChoicesSection: {
    id: 'achInformationAndFundingChoicesSection',
    // creditAndDebit
    bankName: baseFormFields.bankName,
    nameOnAccountSameAsLegalName: {
      id: 'nameOnAccountSameAsLegalName',
      name: 'nameOnAccountSameAsLegalName',
      label: 'Name on Bank Account same as Legal Business Name',
      type: 'radio',
      fieldType: 'radio',
      fields: [
        { label: 'Yes', value: 'yes' },
        { label: 'No', value: 'no' }
      ],
      controls: {
        no: {
          nameOnAccount: baseFormFields.nameOnAccount
        }
      }
    },
    fundingChoiceSubSection: {
      fieldType: 'formSectionHeader',
      id: `fundingChoiceSubSection`,
      title: 'Funding Choice Options',
      isSubSection: true
    },
    fundingChoice: baseFormFields.fundingChoice
  }
};

const pricingHelper = ({
  type,
  noHeader = false,
  riskLevel = undefined,
  isNetevia = false,
  perTransaction = true
}) => {
  const titleMap = {
    visaMastercardRate: isNetevia ? 'Visa/Mastercard/Discover Pricing' : 'Visa/Mastercard Pricing',
    amexRate: 'Amex Pricing',
    discoverRate: 'Discover Pricing',
    pinDebitFees: 'Pin-Debit Fees Pricing'
  };
  const sectionTitle = titleMap[type] || camelToTitle(type);
  const rate =
    riskLevel !== 'elevated' && (type === 'flatPricing' || type === 'cashDiscountPricing')
      ? 'rateFivePrecision'
      : 'rate';
  const pricingFields = {
    ...(!noHeader && {
      [`${type}SubSection`]: {
        fieldType: 'formSectionHeader',
        id: `${type}SubSection`,
        title: sectionTitle,
        isSubSection: true
      }
    }),
    [`${type}_volumeRatio`]: {
      ...ratesAndFeesSharedFields[rate],
      wrapperStyle: {
        flex: '33%'
      },
      id: `${type}_volumeRatio`,
      label: 'Volume'
    },
    ...(perTransaction
      ? {
          [`${type}_perTransaction`]: {
            ...ratesAndFeesSharedFields.authorization,
            wrapperStyle: {
              flex: '33%'
            },
            id: `${type}_perTransaction`,
            label: 'Authorizations'
          }
        }
      : {}),
    ...(type === 'pinDebitFees' && {
      [`${type}_monthlyFee`]: {
        ...ratesAndFeesSharedFields.authorization,
        wrapperStyle: {
          flex: '33%'
        },
        id: `${type}_monthlyFee`,
        label: 'Monthly Fee'
      }
    })
  };
  return pricingFields;
};

const tieredHelper = (varName, subSectionName) => {
  const sectionTitle = `${subSectionName}`;
  return {
    [`${varName}SubSection`]: {
      fieldType: 'formSectionHeader',
      id: `${varName}SubSection`,
      title: sectionTitle,
      isSubSection: true
    },
    [`${varName}_qualifiedRate_volumeRatio`]: {
      ...ratesAndFeesSharedFields.rate,
      id: `${varName}_qualifiedRate_volumeRatio`,
      label: 'Qualified Rate'
    },
    [`${varName}_qualifiedRate_perTransaction`]: {
      ...ratesAndFeesSharedFields.authorization,
      id: `${varName}_qualifiedRate_perTransaction`,
      label: 'Qualified Authorizations'
    },
    [`${varName}_midQualifiedRate_volumeRatio`]: {
      ...ratesAndFeesSharedFields.rate,
      id: `${varName}_midQualifiedRate_volumeRatio`,
      label: 'Mid Qualified Rate'
    },
    [`${varName}_midQualifiedRate_perTransaction`]: {
      ...ratesAndFeesSharedFields.authorization,
      id: `${varName}_midQualifiedRate_perTransaction`,
      label: 'Mid Qualified Authorizations'
    },
    [`${varName}_nonQualifiedRate_volumeRatio`]: {
      ...ratesAndFeesSharedFields.rate,
      id: `${varName}_nonQualifiedRate_volumeRatio`,
      label: 'Non-Qualified Rate'
    },
    [`${varName}_nonQualifiedRate_perTransaction`]: {
      ...ratesAndFeesSharedFields.authorization,
      id: `${varName}_nonQualifiedRate_perTransaction`,
      label: 'Non-Qualified Authorizations'
    }
  };
};

const interchangeBundledFields = (options) => {
  const { isNetevia = false } = options || {};
  return {
    ...pricingHelper({ type: 'visaMastercardRate', isNetevia, perTransaction: !isNetevia }),
    ...pricingHelper({ type: 'amexRate', perTransaction: !isNetevia }),
    ...(isNetevia ? {} : { ...pricingHelper({ type: 'discoverRate' }) })
  };
};

export const mpaCustomBackendFormats = {
  // Custom backend formatting for MPA fields
  fees: {
    waiveFees: (value) => {
      if (isEmpty(value)) {
        return { waiveDuesAndAssessments: null, waiveCardBrandFees: null };
      }
      if (value === 'waiveBoth' || value === 'waiveNeither') {
        const shouldWaiveBoth = value === 'waiveBoth';
        return { waiveDuesAndAssessments: shouldWaiveBoth, waiveCardBrandFees: shouldWaiveBoth };
      }
      return {
        waiveDuesAndAssessments: value === 'waiveDuesAndAssessments',
        waiveCardBrandFees: value === 'waiveCardBrandFees'
      };
    }
  }
};

const sharedWaiveFeesRadio = {
  // FE only
  label: 'Waive Fees',
  type: 'radio',
  fieldType: 'radio',
  required: false,
  wrapperStyle: { flex: '100%' },
  backendFormat: mpaCustomBackendFormats.fees.waiveFees,
  fields: [
    {
      label: 'Waive Only Card Brand',
      value: 'waiveCardBrandFees',
      tooltipProps: { isHtml: true },
      tooltip: (
        <div
          style={{
            color: 'var(--color-text)',
            display: 'flex',
            flexDirection: 'column',
            padding: '0.4em'
          }}>
          <strong>These fees will be waived:</strong>
          <div style={{ display: 'flex', flexDirection: 'row', gap: '0.2em' }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {[
                'D Trans Integrity',
                'V Excess Att',
                'V High Fallback',
                'D AVS Fee',
                'V App Att Fee',
                'V AVS',
                'DEF Max',
                'NABU Rate',
                'Misuse Auth M',
                'Acct Inq Rate',
                'Cross Border/Acq Sup %',
                'CVC2',
                'DEF %',
                'M Location Fee',
                'APF Credit Rate',
                'APF Debit Rate',
                'File Transfer',
                'IAF %',
                'ISA %'
              ].map((fee, i) => (
                <div
                  key={fee}
                  style={{
                    padding: '0.2em',
                    ...(i % 2 === 0 && { backgroundColor: 'var(--color-air)' })
                  }}>
                  {fee}
                </div>
              ))}
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {[
                'Misuse Auth V',
                'Floor Limit Rate',
                'Integrity Rate',
                'FANF Rate',
                'FANF Fee',
                'DUC Fee',
                'IPF/ISF Fee %',
                'DNAF',
                'Assessment A Fee %',
                'Non-Swiped A Fee',
                'Inbound Fee %',
                'Data Quality Fee %',
                'Processing Fee - A %',
                'V Excess Att XB',
                'M Acq Merch Advice',
                'AcqTran A',
                'D DigInv Fee',
                'V App Att Fee XB',
                'DEF Min'
              ].map((fee, i) => (
                <div
                  key={fee}
                  style={{
                    padding: '0.2em',
                    ...(i % 2 !== 0 && { backgroundColor: 'var(--color-air)' })
                  }}>
                  {fee}
                </div>
              ))}
            </div>
          </div>
        </div>
      )
    },
    {
      label: 'Waive Only Dues & Assessments',
      value: 'waiveDuesAndAssessments',
      tooltip:
        'Card Brand Dues and Assessments billed to some merchant accounts based on transaction type'
    },
    { label: 'Waive Both', value: 'waiveBoth' },
    { label: 'Waive Neither', value: 'waiveNeither' }
  ]
};

export const feesTabFormFieldsCrab = (options) => {
  const { processName, riskLevel } = options || {};
  const isPreferred = riskLevel === 'preferred';
  return {
    // --------------------------- FEES TAB --------------------------- //
    ratesAndFeesSection: {
      id: 'ratesAndFeesSection',
      pricingType: {
        ...baseFormFields.pricingType,
        list: [
          { title: 'Interchange Plus', value: 'interchange_plus' },
          { title: 'Bundled', value: 'bundled' },
          { title: 'Tiered Rates', value: 'tiered_rates' },
          { title: 'Cash Discount Pricing', value: 'cash_discount_pricing' },
          { title: 'Flat Pricing', value: 'flat_pricing' }
        ],
        controls: {
          interchange_plus: { ...interchangeBundledFields() },
          bundled: { ...interchangeBundledFields() },
          cash_discount_pricing: {
            ...pricingHelper({ type: 'cashDiscountPricing', riskLevel }),
            cashDiscountPricingWaiveFees: {
              ...sharedWaiveFeesRadio,
              id: 'cashDiscountPricingWaiveFees',
              name: 'cashDiscountPricingWaiveFees',
              ...(processName === 'repay' && { required: true })
            }
          },
          flat_pricing: {
            ...pricingHelper({ type: 'flatPricing', riskLevel }),
            flatPricingWaiveFees: {
              ...sharedWaiveFeesRadio,
              id: 'flatPricingWaiveFees',
              name: 'flatPricingWaiveFees',
              ...(processName === 'repay' && { required: true })
            }
          }
        }
      }
    },

    pinDebitFeesSection: {
      id: 'pinDebitFeesSection',
      pinBasedDebitEnabled: {
        id: 'pinBasedDebitEnabled',
        name: 'pinBasedDebitEnabled',
        label: 'PIN Based Debit',
        required: false,
        ...toggleFieldOptions,
        controls: {
          true: { ...pricingHelper({ type: 'pinDebitFees', noHeader: true }) }
        }
      }
    },

    tieredRatesSection: {
      id: 'tieredRatesSection',
      ...tieredHelper('visaMastercard', 'Visa/Mastercard'),
      ...tieredHelper('amex', 'Amex'),
      ...tieredHelper('discover', 'Discover'),
      ...tieredHelper('debit', 'Debit')
    },

    otherFeesSection: {
      // subSection of ratesAndFees
      id: 'otherFeesSection',
      bankSponsorFee: {
        ...ratesAndFeesSharedFields.rateFivePrecision,
        id: 'bankSponsorFee',
        label: 'Bank Sponsor Fee',
        required: false,
        wrapperStyle: {
          flex: '33%'
        }
      },
      amexSponsorFee: {
        id: 'amexSponsorFee',
        label: 'Amex Sponsor Fee',
        type: 'price',
        prefix: '$',
        priceToNumber: true, // convert from string to number type for BE value
        fieldType: 'input',
        required: false
      },
      governmentCompliance: {
        id: 'governmentCompliance',
        label: 'Govt. Compliance',
        type: 'price',
        prefix: '$',
        priceToNumber: true, // convert from string to number type for BE value
        fieldType: 'input',
        required: false
      },
      pciNonComplianceFeeMonthly: {
        id: 'pciNonComplianceFeeMonthly',
        label: 'Monthly PCI Non-Compliance Fee',
        type: 'price',
        prefix: '$',
        labelStyle: { whiteSpace: 'break-spaces' },
        priceToNumber: true, // convert from string to number type for BE value
        fieldType: 'input'
      },
      pciComplianceMonthly: {
        id: 'pciComplianceMonthly',
        label: 'Monthly PCI Compliance Fee',
        type: 'price',
        prefix: '$',
        labelStyle: { whiteSpace: 'break-spaces' },
        priceToNumber: true, // convert from string to number type for BE value
        fieldType: 'input'
      },
      cardBrandRegistration: {
        id: 'cardBrandRegistration',
        label: 'Card Brand Registration',
        type: 'price',
        prefix: '$',
        priceToNumber: true, // convert from string to number type for BE value
        fieldType: 'input',
        required: false,
        ...(processName === 'repay' && { required: true })
      },
      statementFee: {
        id: 'statementFee',
        label: 'Statement Fee',
        type: 'price',
        prefix: '$',
        priceToNumber: true, // convert from string to number type for BE value
        fieldType: 'input'
      },
      minimumMonthlyFee: {
        id: 'minimumMonthlyFee',
        label: 'Minimum Monthly Fee',
        type: 'price',
        prefix: '$',
        priceToNumber: true, // convert from string to number type for BE value
        fieldType: 'input'
      },
      setupFee: {
        id: 'setupFee',
        label: 'Setup Fee',
        type: 'price',
        prefix: '$',
        priceToNumber: true, // convert from string to number type for BE value
        fieldType: 'input',
        required: false,
        ...(processName === 'repay' && { required: true })
      },
      annualFee: baseFormFields.annualFee,
      monthlyFee: {
        ...baseFormFields.monthlyFee,
        ...(processName === 'repay' && { required: true })
      },
      monthlyRegistrationFee: {
        id: 'monthlyRegistrationFee',
        label: 'Monthly Registration Fee',
        type: 'price',
        priceToNumber: true, // convert from string to number type for BE value
        prefix: '$',
        fieldType: 'input',
        required: false
      },
      verifiAlert: {
        id: 'verifiAlert',
        label: 'Verifi Alert',
        type: 'price',
        prefix: '$',
        priceToNumber: true, // convert from string to number type for BE value
        fieldType: 'input',
        required: false
      },
      rapidDisputeResolution: {
        id: 'rapidDisputeResolution',
        label: 'Rapid Dispute Resolution',
        type: 'price',
        prefix: '$',
        priceToNumber: true, // convert from string to number type for BE value
        fieldType: 'input',
        required: false
      },
      verifiedByVisa: {
        id: 'verifiedByVisa',
        label: 'Verified By Visa',
        type: 'price',
        prefix: '$',
        priceToNumber: true, // convert from string to number type for BE value
        fieldType: 'input',
        required: false
      },
      ethocaAlert: {
        id: 'ethocaAlert',
        label: 'Ethoca Alert',
        type: 'price',
        prefix: '$',
        priceToNumber: true, // convert from string to number type for BE value
        fieldType: 'input',
        required: false
      },
      mastercardSecureCode: {
        id: 'mastercardSecureCode',
        label: 'Mastercard Secure Code',
        type: 'price',
        prefix: '$',
        priceToNumber: true, // convert from string to number type for BE value
        fieldType: 'input',
        required: false
      },
      webMonitoring: {
        id: 'webMonitoring',
        label: 'Web Monitoring',
        type: 'price',
        prefix: '$',
        priceToNumber: true, // convert from string to number type for BE value
        fieldType: 'input',
        required: false
      },
      retrievals: {
        id: 'retrievals',
        label: 'Retrievals',
        type: 'price',
        prefix: '$',
        priceToNumber: true, // convert from string to number type for BE value
        fieldType: 'input'
      },
      chargebacks: {
        id: 'chargebacks',
        label: 'Chargebacks',
        type: 'price',
        prefix: '$',
        priceToNumber: true, // convert from string to number type for BE value
        fieldType: 'input'
      },
      reversal: {
        id: 'reversal',
        label: 'Reversal',
        type: 'price',
        prefix: '$',
        priceToNumber: true, // convert from string to number type for BE value
        fieldType: 'input',
        required: false,
        ...(processName === 'repay' && { required: true })
      },
      preArbitration: {
        id: 'preArbitration',
        label: 'Pre-Arbitration',
        type: 'price',
        prefix: '$',
        priceToNumber: true, // convert from string to number type for BE value
        fieldType: 'input',
        initialValue: isPreferred ? '25' : '35',
        errorMessage: `Must be a valid price, ${isPreferred ? '25' : '35'} or higher`,
        customValidation: isPreferred
          ? customValidations.preArbitrationLow
          : customValidations.preArbitrationHigh,
        required: false
      },
      arbitration: {
        id: 'arbitration',
        label: 'Arbitration',
        type: 'price',
        prefix: '$',
        priceToNumber: true, // convert from string to number type for BE value
        fieldType: 'input',
        initialValue: '25',
        required: false
      },
      riskAnalysis: {
        id: 'riskAnalysis',
        label: 'Risk Analysis',
        type: 'price',
        prefix: '$',
        priceToNumber: true, // convert from string to number type for BE value
        fieldType: 'input',
        required: false
      },
      batchFee: {
        id: 'batchFee',
        label: 'Batch Fee',
        type: 'price',
        prefix: '$',
        priceToNumber: true, // convert from string to number type for BE value
        fieldType: 'input'
      },
      declineTransaction: {
        id: 'declineTransaction',
        label: 'Decline / TXN',
        type: 'price',
        prefix: '$',
        priceToNumber: true, // convert from string to number type for BE value
        fieldType: 'input',
        required: false,
        ...(processName === 'repay' && { required: true })
      },
      refundTransaction: {
        id: 'refundTransaction',
        label: 'Refunds / TXN',
        type: 'price',
        prefix: '$',
        priceToNumber: true, // convert from string to number type for BE value
        fieldType: 'input',
        required: false
      },
      avsTransaction: {
        id: 'avsTransaction',
        label: 'AVS / TXN',
        type: 'price',
        prefix: '$',
        priceToNumber: true, // convert from string to number type for BE value
        fieldType: 'input'
      },
      voiceAuthorizationTransaction: {
        id: 'voiceAuthorizationTransaction',
        label: 'Voice Auth / TXN',
        type: 'price',
        prefix: '$',
        priceToNumber: true, // convert from string to number type for BE value
        fieldType: 'input'
      },
      onlinePortalAccessMonthly: {
        id: 'onlinePortalAccessMonthly',
        label: 'Monthly Online Portal Access',
        type: 'price',
        prefix: '$',
        labelStyle: { whiteSpace: 'break-spaces' },
        priceToNumber: true, // convert from string to number type for BE value
        fieldType: 'input',
        required: false,
        ...(processName === 'repay' && { required: true })
      },
      terminalFee: {
        id: 'terminalFee',
        label: 'Terminal Fee',
        type: 'price',
        prefix: '$',
        priceToNumber: true, // convert from string to number type for BE value
        fieldType: 'input',
        required: false,
        ...(processName === 'repay' && { required: true })
      },
      posFee: {
        id: 'posFee',
        label: 'POS Fee',
        type: 'price',
        prefix: '$',
        priceToNumber: true, // convert from string to number type for BE value
        fieldType: 'input',
        required: false,
        ...(processName === 'repay' && { required: true })
      },
      perAchReject: {
        id: 'perAchReject',
        label: 'Per ACH Reject',
        type: 'price',
        prefix: '$',
        priceToNumber: true, // convert from string to number type for BE value
        fieldType: 'input'
      },
      tinMismatchMonthly: {
        id: 'tinMismatchMonthly',
        label: 'Monthly TIN Mis-Match',
        type: 'price',
        prefix: '$',
        priceToNumber: true, // convert from string to number type for BE value
        fieldType: 'input'
      },
      ...(riskLevel !== 'elevated' &&
        riskLevel !== 'standard' && {
          nextDayFundingBatchFee: {
            // required if fundingChoice === next_day_funding
            id: 'nextDayFundingBatchFee',
            label: 'Next Day Funding Batch Fee (Required if Funding Choice is Next Day Funding)',
            type: 'price',
            prefix: '$',
            priceToNumber: true, // convert from string to number type for BE value
            fieldType: 'input',
            required: false,
            wrapperStyle: { flex: '100%' },
            labelStyle: { whiteSpace: 'break-spaces' }
          }
        })
    },
    accountUpdaterSection: {
      id: 'accountUpdaterSection',
      accountUpdaterEnabled: {
        ...baseFormFields.accountUpdaterEnabled,
        required: false,
        controls: {
          true: {
            setup: baseFormFields.setup,
            monthly: baseFormFields.monthly,
            perTransaction: baseFormFields.perTransaction
          }
        }
      }
    },
    mobileProcessingSection: {
      id: 'mobileProcessingSection',
      mobileProcessingEnabled: {
        ...baseFormFields.mobileProcessingEnabled,
        required: false,
        controls: {
          true: {
            setup: baseFormFields.setup,
            monthly: baseFormFields.monthly,
            perTransaction: baseFormFields.perTransaction,
            perUser: baseFormFields.perUser
          }
        }
      }
    },
    gatewaySection: {
      id: 'gatewaySection',
      gatewayEnabled: {
        ...baseFormFields.gatewayEnabled,
        required: false,
        controls: {
          true: {
            setup: { ...baseFormFields.setup, ...(processName === 'repay' && { required: true }) },
            monthly: baseFormFields.monthly,
            perTransaction: baseFormFields.perTransaction,
            gatewayName: {
              ...baseFormFields.gatewayName,
              required: false,
              ...(processName === 'repay' && { required: true })
            },
            gatewayVaultTokenizationMonthly: {
              ...baseFormFields.gatewayVaultTokenizationMonthly,
              required: false,
              ...(processName === 'repay' && { required: true })
            },
            gatewayVaultTokenizationPerTransaction: {
              ...baseFormFields.gatewayVaultTokenizationPerTransaction,
              required: false,
              ...(processName === 'repay' && { required: true })
            }
          }
        }
      }
    },
    wirelessProcessingSection: {
      id: 'wirelessProcessingSection',
      wirelessProcessingEnabled: {
        ...baseFormFields.wirelessProcessingEnabled,
        required: false,
        controls: {
          true: {
            setup: baseFormFields.setup,
            monthly: baseFormFields.monthly,
            perTransaction: baseFormFields.perTransaction
          }
        }
      }
    },
    declineRecoverySection: {
      id: 'declineRecoverySection',
      declineRecoveryEnabled: {
        id: 'declineRecoveryEnabled',
        name: 'declineRecoveryEnabled',
        label: 'Decline Recovery',
        ...toggleFieldOptions,
        required: false,
        controls: {
          true: {
            recoveryFee: {
              id: 'recoveryFee',
              label: 'Recovery Fee',
              type: 'percent',
              suffixType: 'basisPoints',
              suffix: '%',
              isRatio: true,
              fieldType: 'input'
            },
            transactionFee: {
              id: 'transactionFee',
              label: 'TXN Fee',
              type: 'price',
              prefix: '$',
              priceToNumber: true, // if BE expects value as number type but FE uses string type
              fieldType: 'input'
            },
            monthlyFee: baseFormFields.monthlyFee,
            other: {
              id: 'other',
              label: 'Other',
              type: 'price',
              prefix: '$',
              priceToNumber: true, // if BE expects value as number type but FE uses string type
              fieldType: 'input'
            }
          }
        }
      }
    }
  };
};

export const feesTabFormFieldsNetevia = (options) => {
  const { riskLevel } = options || {};
  const formFields = feesTabFormFieldsCrab({ riskLevel, processName: 'netevia' });
  return {
    ratesAndFeesSection: {
      id: formFields.ratesAndFeesSection.id,
      pricingType: {
        ...formFields.ratesAndFeesSection.pricingType,
        list: [
          { title: 'Interchange Plus', value: 'interchange_plus' },
          { title: 'Tiered Rates', value: 'tiered_rates' }
        ],
        controls: {
          interchange_plus: { ...interchangeBundledFields({ isNetevia: true }) }
        }
      }
    },
    pinDebitFeesSection: {
      id: formFields.pinDebitFeesSection.id,
      pinBasedDebitEnabled: formFields.pinDebitFeesSection.pinBasedDebitEnabled
    },
    tieredRatesSection: {
      id: formFields.tieredRatesSection.id,
      ...tieredHelper('debit', 'Visa/Mastercard/Discover Debit'),
      ...tieredHelper('visaMastercard', 'Visa/Mastercard/Discover Credit'), // BE uses `visaMastercard` key in PDF mapping
      ...tieredHelper('amex', 'Amex Opt Blue')
    },
    otherFeesSection: {
      id: formFields.otherFeesSection.id,
      pciNonComplianceFeeMonthly: formFields.otherFeesSection.pciNonComplianceFeeMonthly,
      pciComplianceMonthly: formFields.otherFeesSection.pciComplianceMonthly,
      statementFee: formFields.otherFeesSection.statementFee,
      minimumMonthlyFee: formFields.otherFeesSection.minimumMonthlyFee,
      monthlyFee: formFields.otherFeesSection.monthlyFee,
      annualFee: formFields.otherFeesSection.annualFee,
      retrievals: formFields.otherFeesSection.retrievals,
      chargebacks: formFields.otherFeesSection.chargebacks,
      batchFee: formFields.otherFeesSection.batchFee,
      avsTransaction: formFields.otherFeesSection.avsTransaction,
      voiceAuthorizationTransaction: formFields.otherFeesSection.voiceAuthorizationTransaction,
      perAchReject: formFields.otherFeesSection.perAchReject,
      tinMismatchMonthly: formFields.otherFeesSection.tinMismatchMonthly,
      ...(riskLevel !== 'elevated' &&
        riskLevel !== 'standard' && {
          nextDayFundingBatchFee: formFields.otherFeesSection.nextDayFundingBatchFee
        })
    },
    gatewaySection: {
      id: formFields.gatewaySection.id,
      gatewayEnabled: {
        ...formFields.gatewaySection.gatewayEnabled,
        controls: {
          true: {
            monthly: baseFormFields.monthly,
            perTransaction: baseFormFields.perTransaction
          }
        }
      }
    },
    wirelessProcessingSection: formFields.wirelessProcessingSection
  };
};

// if you modify the this, make sure allCrabRestrictedTags can still grab all the tags it
export const crabUploadsTabWetSignedOptions = {
  signedMpa: {
    displayText: 'Wet Signed MPA',
    backendKey: 'signedMpa',
    tagList: ['signed_mpa']
  },
  corviaReserve: {
    displayText: 'Corvia Reserve Addendum',
    backendKey: 'corviaReserveAddendum',
    tagList: ['corvia_reserve_form']
  },
  productCountryCompliance: {
    displayText: 'Corvia Product Country Compliance Addendum',
    backendKey: 'productCountryCompliance',
    tagList: ['product_country_compliance']
  },
  nobAddendum: {
    displayText: 'NOB Addendum',
    backendKey: 'nobAddendum',
    tagList: ['nob_addendum']
  },
  outsideLegalReview: {
    displayText: 'Outside Legal Review Addendum',
    backendKey: 'outsideLegalReview',
    tagList: ['outside_legal_review']
  },
  neteviaReserveAddendum: {
    displayText: 'Netevia Reserve Addendum',
    backendKey: 'neteviaReserveAddendum',
    tagList: ['netevia_reserve_form']
  }
};

export const allCrabRestrictedTags = () => {
  const addendumFilesTags = Object.values(crabUploadsTabWetSignedOptions).reduce(
    (acc, aFile, _index) => [...acc, ...aFile.tagList],
    []
  );
  const baseApplicationRequiredTags = [...crabRequiredFiles, ...getCrabRequiredDdaFiles()].reduce(
    (acc, aFile, _index) => {
      const { required, allowedOptions, tagList } = aFile;
      if (isEmpty(allowedOptions)) {
        return [...acc, ...(required ? tagList : [])];
      }
      const allNestedAllowedTags = required
        ? allowedOptions.reduce(
            (InnerAcc, aAllowedOption) => [...InnerAcc, ...aAllowedOption.tagList],
            []
          )
        : [];
      return [...acc, ...allNestedAllowedTags];
    },
    []
  );
  return [...new Set([...addendumFilesTags, ...baseApplicationRequiredTags])];
};

const ddaFileUploadTypes = {
  // Separate DDA upload file types that could be required
  // businessTab (ACH Info for Credit & Debit -or- Credit Only)
  creditAndDebit: { value: 'Credit & Debit', title: 'Credit & Debit' },
  creditOnly: { value: 'Credit Only', title: 'Credit Only' },
  // repayTab only
  debitOnly: { value: 'Debit Only', title: 'Debit Only' },
  ddaForChargebacksOnly: { value: 'DDA For Chargebacks Only', title: 'DDA For Chargebacks Only' },
  // priorityTab only
  billingAccount: { value: 'Billing Account', title: 'Billing Account' },
  fundingAccount: { value: 'Funding Account', title: 'Funding Account' }
};

export const getCrabRequiredDdaFiles = (options) => {
  const { isPublicRequest, mpaData, relationship, billingSameAsPrimary } = options || {};
  const formattedRelationship = getFormattedRelationship(relationship);
  const { processName } = formattedRelationship || {};
  const isRepay = processName === 'repay';
  const isPriority = processName === 'priority';
  /** netevia, default processor - possible bank account data:
   * achInfoAndFunding.creditAndDebit.accountNumber (businessTab)
   */
  const ddaFilesDefault = [{ ...ddaFileUploadTypes.creditAndDebit }];
  let requiredDdaFiles = ddaFilesDefault;
  if (isRepay) {
    /** repay - possible bank account data:
     * achInfoAndFunding.creditAndDebit.accountNumber (businessTab)
     * repayFields.achInfoAndFunding.debitOnly.accountNumber (repayTab)
     * repayFields.achInfoAndFunding.chargebackOnly.accountNumber (repayTab)
     */
    const repayTabName = isPublicRequest ? 'processorFieldsTab' : 'repayTab';
    const { [repayTabName]: repayTabSections } = mpaData || {};
    const { repayAchInfoAndFundingSection } = repayTabSections || {};
    const { selectAchAccountRadio } = repayAchInfoAndFundingSection?.valuesForBackend || {};
    const useCreditAndDebitOnly = isEmpty(selectAchAccountRadio);
    const useDebitOnly = selectAchAccountRadio === 'use_debit_only';
    const useDdaForChargebacksOnly = selectAchAccountRadio === 'use_dda_for_chargebacks_only';
    const ddaFilesRepay = useCreditAndDebitOnly
      ? [
          // If repayTab achInfo section DOES NOT have an additional ACH account selected
          { ...ddaFileUploadTypes.creditAndDebit }
        ]
      : // If repayTab achInfo section DOES have an additional ACH account selected
        [
          { ...ddaFileUploadTypes.creditOnly }, // businessTab achInfo - must be for credit only
          ...(useDebitOnly ? [{ ...ddaFileUploadTypes.debitOnly }] : []),
          ...(useDdaForChargebacksOnly ? [{ ...ddaFileUploadTypes.ddaForChargebacksOnly }] : [])
        ];
    requiredDdaFiles = ddaFilesRepay;
  } else if (isPriority) {
    const isPriorityTsys = isPriorityTsysBank(formattedRelationship);
    /** priority/tsys - possible bank account data:
     * priorityFields.achInfoAndFunding.billingAccount.accountNumber (priorityTab)
     * priorityFields.achInfoAndFunding.fundingAccount.accountNumber (priorityTab)
     */
    /** priority/first data - possible bank account data:
     * priorityFields.achInfoAndFunding.fundingAccount.accountNumber (priorityTab)
     */
    const ddaFilesPriority = [
      { ...ddaFileUploadTypes.fundingAccount }, // required for priority (all banks)
      ...(isPriorityTsys && !billingSameAsPrimary ? [{ ...ddaFileUploadTypes.billingAccount }] : []) // required for TSYS
    ];
    requiredDdaFiles = ddaFilesPriority;
  }
  return [
    {
      displayText: 'Voided Check/Bank Letter',
      backendKey: 'voidedCheckOrBankLetter',
      required: true,
      allowedOptions: [
        // When only one file from a set list is required
        {
          title: 'Voided Check',
          value: 'voided_check',
          backendKey: 'voidedCheck',
          mapsToBackendKey: 'voidedCheckOrBankLetter',
          tagList: ['voided_check_or_bank_letter']
        },
        {
          title: 'Bank Letter',
          value: 'bank_letter',
          backendKey: 'bankLetter',
          mapsToBackendKey: 'voidedCheckOrBankLetter',
          tagList: ['voided_check_or_bank_letter']
        }
      ],
      requiredFileSubTypes: requiredDdaFiles
    }
  ];
};

// if you modify the this, make sure allCrabRestrictedTags can still grab all the tags it
export const crabRequiredFiles = [
  {
    displayText: 'Photo ID',
    backendKey: 'photoId',
    tagList: ['photo_id'],
    required: true
  },
  {
    displayText: 'Bank Statement',
    backendKey: 'bankStatement',
    tagList: ['bank_statements'],
    required: false
  },
  {
    displayText: 'Processing Statement',
    backendKey: 'processingStatement',
    tagList: ['processing_statements'],
    required: false
  },
  {
    displayText: 'Business License',
    backendKey: 'businessLicense',
    tagList: ['business_license'],
    required: false
  },
  {
    displayText: 'Articles of Incorporation',
    backendKey: 'articlesOfIncorporation',
    tagList: ['articles_of_incorporation'],
    required: false
  },
  {
    displayText: 'Business Financials',
    backendKey: 'businessFinancials',
    tagList: ['business_financials'],
    required: false
  },
  {
    displayText: 'Other',
    backendKey: 'other',
    useFileNameInput: true,
    required: false
  }
];

export const uploadsTabFormFieldsCrab = {
  signatureSection: {
    id: 'signatureSection',
    signatureType: { ...baseFormFields.signatureType, disabled: true }
  }
};

export const addendumTabFormFieldsCrab = {
  alwaysShownControls: {
    id: 'alwaysShownControls',
    usingCorviaProductCountryCompliance: {
      id: 'usingCorviaProductCountryCompliance',
      name: 'usingCorviaProductCountryCompliance',
      label: 'Use Corvia Product Country Compliance',
      ...toggleFieldOptions,
      wrapperStyle: {
        ...toggleFieldOptions.wrapperStyle,
        borderTop: '1px solid black'
      },
      required: false
    },
    usingNobAddendum: {
      id: 'usingNobAddendum',
      name: 'usingNobAddendum',
      label: 'Use NOB Addendum',
      ...toggleFieldOptions,
      wrapperStyle: {
        ...toggleFieldOptions.wrapperStyle,
        borderTop: '1px solid black'
      },
      required: false
    }
  },
  outsideLegalReviewFormFields: {
    id: 'outsideLegalReviewFormFields',
    usingOutsideLegalReviewForm: {
      ...toggleFieldOptions,
      id: 'usingOutsideLegalReviewForm',
      name: 'usingOutsideLegalReviewForm',
      label: 'Use Outside Legal Review',
      required: false,
      controls: {
        true: {
          nameOfAttorney: {
            id: 'nameOfAttorney',
            label: 'Name Of Attorney',
            type: 'text',
            fieldType: 'input',
            required: true
          },
          nameOfLawFirm: {
            id: 'nameOfLawFirm',
            label: 'Name Of Law Firm',
            type: 'text',
            fieldType: 'input',
            required: true
          },
          name: {
            id: 'name',
            label: 'Signee Name',
            type: 'text',
            fieldType: 'input',
            required: true
          },
          title: {
            id: 'title',
            label: 'Signee Title',
            type: 'text',
            fieldType: 'input',
            required: true,
            wrapperStyle: { margin: 'unset', flex: '33%' }
          }
        }
      }
    }
  },
  neteviaReserveFormFields: {
    id: 'neteviaReserveFormFields',
    usingNeteviaReserveForm: {
      // FE-only checkbox
      ...toggleFieldOptions,
      id: 'usingNeteviaReserveForm',
      name: 'usingNeteviaReserveForm',
      label: 'Netevia Reserve Addendum (Required)',
      required: false,
      disabled: true,
      controls: {
        true: {
          initialCheckOrWitholdingRadio: {
            // FE-only radio
            id: 'initialCheckOrWitholdingRadio',
            name: 'initialCheckOrWitholdingRadio',
            label: 'The reserve account will be established by',
            type: 'radio',
            fieldType: 'radio',
            required: true,
            fields: [
              { label: 'Initial Check', value: 'initialCheckAmountRadio' },
              { label: 'Withholding', value: 'withholdingRatioRadio' }
            ],
            controls: {
              initialCheckAmountRadio: {
                initialCheckAmount: {
                  id: 'initialCheckAmount',
                  label: 'Initial Check Amount',
                  fieldType: 'input',
                  required: true,
                  type: 'price',
                  priceToNumber: true
                }
              },
              withholdingRatioRadio: {
                withholdingRatio: {
                  id: 'withholdingRatio',
                  label: 'Withholding Percent',
                  type: 'percent',
                  suffixType: 'basisPoints',
                  fieldType: 'input',
                  required: true,
                  isRatio: true
                }
              }
            }
          },
          ownershipType: {
            id: 'ownershipType',
            name: 'ownershipType',
            label: 'Ownership Type',
            type: 'radio',
            fieldType: 'radio',
            required: true,
            fields: [
              { label: 'Owner', value: 'owner' },
              { label: 'President', value: 'president' }
            ]
          }
        }
      }
    }
  },
  corviaReserveFormFields: {
    id: 'corviaReserveFormFields',
    usingCorviaReserveForm: {
      // FE-only checkbox
      ...toggleFieldOptions,
      id: 'usingCorviaReserveForm',
      name: 'usingCorviaReserveForm',
      label: 'Use Corvia Reserve Addendum (Required)',
      required: false,
      disabled: true
    }
  }
};

const repayTabFormFields = (options = {}) => ({
  ...paymentMethodsAcceptedFieldsCrab,
  ...repayGeneralFormFieldsCrab(options),
  repayAchInfoAndFundingSection: {
    // repay-specific section for `achInfoAndFunding`
    id: 'repayAchInfoAndFundingSection',
    ...getRepayAchInfoAndFundingFields(options)
  },
  repayAchInfoConfirmSection: {
    // repay-specific section for `achInfoAndFunding`
    // section to re-enter accountNumber, routingNumber fields
    id: 'repayAchInfoConfirmSection',
    routingNumber: { ...baseFormFields.routingNumber, label: 'ABA Routing Number' },
    accountNumber: baseFormFields.accountNumber
  }
});

export const utilizeRepayAchAccountRadio = (relationship) => {
  const { crabConfigurationOptions } = relationship || {};
  const { ddaForChargebacksOnly } = crabConfigurationOptions || {};
  return ddaForChargebacksOnly;
};

const getRepayAchInfoAndFundingFields = (relationship) => {
  const useAchAccountRadio = utilizeRepayAchAccountRadio(relationship);
  const { crabConfigurationOptions } = relationship || {};
  const { ddaForChargebacksOnly } = crabConfigurationOptions || {};
  if (useAchAccountRadio) {
    return {
      selectAchAccountRadio: {
        // FE-only radio
        ...baseRepayFields.selectAchAccountRadio,
        fields: [
          // FE-only values
          { label: 'Debit transactions', value: 'use_debit_only' },
          ...(ddaForChargebacksOnly
            ? [{ label: 'DDA (Chargebacks Only)', value: 'use_dda_for_chargebacks_only' }]
            : [])
        ],
        required: false,
        controllerStateOverrides: {
          null: {
            // since `required: false`, reset all ach fields
            bankNameDdaForChargebacks: '', // ddaForChargebacksOnly
            bankNameDdaForChargebacksIsValid: true, // ddaForChargebacksOnly
            nameOnAccountDdaForChargebacks: '', // ddaForChargebacksOnly
            nameOnAccountDdaForChargebacksIsValid: true, // ddaForChargebacksOnly
            bankNameDebitOnly: '', // debitOnly
            bankNameDebitOnlyIsValid: true, // debitOnly
            nameOnAccountDebitOnly: '', // debitOnly
            nameOnAccountDebitOnlyIsValid: true // debitOnly
          },
          use_debit_only: {
            // when using debitOnly, the ddaForChargebacksOnly fields should reset
            bankNameDdaForChargebacks: '', // ddaForChargebacksOnly
            bankNameDdaForChargebacksIsValid: true, // ddaForChargebacksOnly
            nameOnAccountDdaForChargebacks: '', // ddaForChargebacksOnly
            nameOnAccountDdaForChargebacksIsValid: true, // ddaForChargebacksOnly
            // debitOnly fields are now required, reset them too
            bankNameDebitOnly: '', // debitOnly
            bankNameDebitOnlyIsValid: false, // debitOnly
            nameOnAccountDebitOnly: '', // debitOnly
            nameOnAccountDebitOnlyIsValid: false // debitOnly
          },
          use_dda_for_chargebacks_only: {
            // when using ddaForChargebacksOnly, the debitOnly fields should reset
            bankNameDebitOnly: '', // debitOnly
            bankNameDebitOnlyIsValid: true, // debitOnly
            nameOnAccountDebitOnly: '', // debitOnly
            nameOnAccountDebitOnlyIsValid: true, // debitOnly
            // chargebackOnly fields are now required, reset them too
            bankNameDdaForChargebacks: '', // ddaForChargebacksOnly
            bankNameDdaForChargebacksIsValid: false, // ddaForChargebacksOnly
            nameOnAccountDdaForChargebacks: '', // ddaForChargebacksOnly
            nameOnAccountDdaForChargebacksIsValid: false // ddaForChargebacksOnly
          }
        },
        controls: {
          use_debit_only: {
            debitAchInfoSectionHeader: {
              // debitOnly
              fieldType: 'formSectionHeader',
              id: `debitAchInfoSectionHeader`,
              title: 'ACH Information (Debit Only)',
              isSubSection: true,
              wrapperStyle: { borderTop: '1px solid black' }
            },
            bankNameDebitOnly: { ...baseFormFields.bankName, id: 'bankNameDebitOnly' },
            nameOnAccountDebitOnly: {
              ...baseFormFields.nameOnAccount,
              id: 'nameOnAccountDebitOnly'
            }
          },
          use_dda_for_chargebacks_only: {
            // ddaForChargebacksOnly
            ddaForChargebacksSectionHeader: {
              fieldType: 'formSectionHeader',
              id: `ddaForChargebacksSectionHeader`,
              title: 'ACH Information (DDA For Chargebacks Only)',
              isSubSection: true,
              wrapperStyle: { borderTop: '1px solid black' }
            },
            bankNameDdaForChargebacks: {
              ...baseFormFields.bankName,
              id: 'bankNameDdaForChargebacks'
            },
            nameOnAccountDdaForChargebacks: {
              ...baseFormFields.nameOnAccount,
              id: 'nameOnAccountDdaForChargebacks'
            }
          }
        }
      }
    };
  }
  return {
    // Using checkbox for debit only
    repayUseDebitCheckbox: {
      ...baseRepayFields.repayUseDebitCheckbox,
      controls: {
        true: {
          debitAchInfoSectionHeader: {
            fieldType: 'formSectionHeader',
            id: `debitAchInfoSectionHeader`,
            title: 'Debit ACH Information',
            isSubSection: true,
            wrapperStyle: { borderTop: '1px solid black' }
          },
          bankName: baseFormFields.bankName,
          nameOnAccount: baseFormFields.nameOnAccount
        }
      }
    }
  };
};

export const neteviaTabFormFields = {
  ...paymentMethodsAcceptedFieldsCrab,
  neteviaCardNotPresentSection: {
    id: 'neteviaCardNotPresentSection',
    internetBusinessType: {
      ...baseNeteviaFields.internetBusinessType,
      controls: {
        other: {
          internetBusinessTypeOther: baseNeteviaFields.internetBusinessTypeOther
        }
      }
    },
    encryptionVendorsAndControls: baseNeteviaFields.encryptionVendorsAndControls,
    advertisingAndPromotionMethod: baseNeteviaFields.advertisingAndPromotionMethod,
    vendors: baseNeteviaFields.vendors,
    fulfillmentVendor: baseNeteviaFields.fulfillmentVendor,
    saleProcessDescription: baseNeteviaFields.saleProcessDescription
  },
  neteviaBillingMethodsSection: {
    id: 'neteviaBillingMethodsSection',
    monthly: baseNeteviaFields.monthly,
    yearly: baseNeteviaFields.yearly,
    quarterly: baseNeteviaFields.quarterly,
    oneTime: baseNeteviaFields.oneTime,
    hourly: baseNeteviaFields.hourly
  },
  neteviaFeesSection: {
    id: 'neteviaFeesSection',
    authVisaMastercardDiscover: baseNeteviaFields.authVisaMastercardDiscover,
    authAmex: baseNeteviaFields.authAmex,
    pciAnnual: baseNeteviaFields.pciAnnual,
    reporting1099K: baseNeteviaFields.reporting1099K,
    earlyTermination: baseNeteviaFields.earlyTermination,
    ebtPerTxn: baseNeteviaFields.ebtPerTxn,
    regulatoryAssistancePerMonth: baseNeteviaFields.regulatoryAssistancePerMonth,
    webMonitoringSetup: baseNeteviaFields.webMonitoringSetup
  }
};

export const crabFeesOnlyFields = (options) => {
  // When viewing ONLY fees data (merchant details) - using `onlyUseFeesData` prop
  const { bankName, processName, riskProfile } = options || {};
  const map = {
    defaultProcessor: feesTabFormFieldsCrab({ processName, riskLevel: riskProfile }),
    repay: feesTabFormFieldsCrab({ processName, riskLevel: riskProfile }),
    priority: feesTabFormFieldsPriority({ bankName, riskLevel: riskProfile }),
    netevia: {
      neteviaFeesSection: neteviaTabFormFields.neteviaFeesSection,
      ...feesTabFormFieldsNetevia({ riskLevel: riskProfile })
    }
  };
  return map[ignoreCase(processName || '')] || map.defaultProcessor;
};

export const getCrabFormFields = (relationship) => {
  const formattedRelationship = getFormattedRelationship(relationship || {});
  const riskProfile = ignoreCase(formattedRelationship.riskProfile || undefined);
  return crabFormFieldsByTab({ ...formattedRelationship, riskLevel: riskProfile });
};

export const crabFormFieldsByTab = (options) => {
  const { bankName, processName, riskProfile, riskLevel } = options || {};
  const isPriority = processName === 'priority';
  const isAxiom = bankName === 'axiom';
  const isPBT = bankName === 'pueblo_bank_and_trust';
  const isSynovus = bankName === 'synovus';
  const isWellsFargo = bankName === 'wells_fargo';
  const relationshipOptions = { ...options, riskLevel: riskLevel || riskProfile };
  return {
    // key names should be the same for any boarding form
    corporation: corporationTabFormFields({
      useAddressAutocomplete: true,
      relationship: relationshipOptions
    }),
    business: businessTabFormFieldsCrab,
    uploads: uploadsTabFormFieldsCrab,
    addendums: addendumTabFormFieldsCrab,
    // processor-specific:
    ...(processName === 'netevia' && {
      netevia: {
        ...neteviaTabFormFields,
        ...feesTabFormFieldsNetevia({ ...relationshipOptions, processName: 'netevia' })
      }
    }),
    ...(processName === 'repay' && {
      repay: {
        ...repayTabFormFields(relationshipOptions),
        ...feesTabFormFieldsCrab({ ...relationshipOptions, processName: 'repay' })
      }
    }),
    ...(isPriority && {
      ...(isAxiom && {
        priority: {
          ...priorityTabFormFields.axiom,
          ...feesTabFormFieldsPriority(relationshipOptions)
        }
      }),
      ...(isSynovus && {
        priority: {
          ...priorityTabFormFields.synovus,
          ...feesTabFormFieldsPriority(relationshipOptions)
        }
      }),
      ...(isPBT && {
        priority: {
          ...priorityTabFormFields.pbt,
          ...feesTabFormFieldsPriority(relationshipOptions)
        }
      }),
      ...(isWellsFargo && {
        priority: {
          ...priorityTabFormFields.wellsFargo,
          ...feesTabFormFieldsPriority(relationshipOptions)
        }
      })
    })
  };
};

const corporationTabFormFields = (options) => ({
  corporateInformationSection: {
    id: 'corporateInformationSection',
    legalBusinessName: baseFormFields.legalBusinessName
  },
  corporateOwnerListSection: {
    // controllingOwner
    id: 'corporateOwnerListSection',
    ...ownerFieldsBundle(options, 'controllingOwner')
  },
  corporateBeneficialOwners: {
    // beneficialOwners
    id: 'beneficialOwners',
    ...ownerFieldsBundle(options, 'beneficialOwners')
  }
});

export const varIndustryList = sortData(
  [
    { title: 'Unknown', value: 'unknown' },
    { title: 'Auto Rental', value: 'auto_rental' },
    { title: 'Bank/Financial Institution', value: 'bank_financial_institution' },
    { title: 'Direct Marketing', value: 'direct_marketing' },
    { title: 'Food/Restaurant', value: 'food_restaurant' },
    { title: 'Grocery Store/Supermarket', value: 'grocery_store_supermarket' },
    { title: 'Hotel', value: 'hotel' },
    { title: 'Limited Amount Terminal', value: 'limited_amount_terminal' },
    {
      title: 'Oil Company/Automated Fueling System',
      value: 'oil_company_automated_fueling_system'
    },
    { title: 'Passenger Transport', value: 'passenger_transport' },
    { title: 'Retail', value: 'retail' }
  ],
  'title'
);
