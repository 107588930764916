import React from 'react';
import { boolToString, formatNumber, isEmpty, snakeToTitle } from '../../../_helpers';
import { formatMid } from '../../../_templateHelpers';

const achDetailsTemplate = {
  frontend: (schema, version) => {
    if (version === '1.0') {
      return formatData(schema);
    }
    return schema?.data || schema;
  },
  toCustomReport: (schema, version) => {
    if (version === '1.0') {
      // we have an odd conversion to a single array here,
      // but i believe it's needed elsewhere so not messing with it here
      const schemaData = schema.data?.[schema.reportName] || [];
      // default to expect the data to be an array of data, with no header data
      let reportData = schemaData;
      let reportHeader = [];
      if (
        schemaData.length === 1 &&
        Object.prototype.hasOwnProperty.call(schemaData[0], 'header')
      ) {
        // test if the data is being returned in an array of one object
        reportData = schemaData[0].data;
        reportHeader = schemaData[0].header;
      }
      const withAchType = {
        ...schema,
        achTypeCamel: schema.reportName,
        data: reportData
      };
      const tableHiddenColumns = setHiddenColumns(reportData, reportHeader, schema.report);
      return {
        chartData: {},
        chartHeader: {},
        tableData: formatData(withAchType),
        tableHeader: createHeader(reportHeader),
        tableHiddenColumns
      };
    }
    return schema?.data || schema;
  }
};

const setHiddenColumns = (reportData, reportHeader, report) => {
  const hiddenColumns = [];
  const selectedColumns = report?.tableColumns ?? [];
  const allColumns = reportHeader.map((item) => item.key);
  allColumns.forEach((column) => {
    if (!selectedColumns.includes(column)) {
      hiddenColumns.push(column);
    }
  });
  return hiddenColumns;
};

const createHeader = (data) => {
  const headerKeyMap = {};
  const columnOrder = [];
  // should already be sorted, but just in case...
  if (data) {
    data.sort((a, b) => a.order - b.order);
    data.forEach((d) => {
      headerKeyMap[d.key] = d.englishName;
      columnOrder.push(d.key);
    });
  }
  return {
    headerKeyMap,
    columnOrder
  };
};

const formatData = (schema) => {
  const {
    achTypeCamel,
    location = '',
    data = [],
    disableMidLink // pass `true` if already viewing the current merchant
  } = schema || {};
  if (achTypeCamel === 'achFundingDetailsList') {
    return !isEmpty(data)
      ? data.map((row) => ({
          achFundingId: row.achFundingId,
          mid: !isEmpty(row.merchant?.mid)
            ? formatMid(row.merchant?.mid, { location, disableMidLink })
            : null,
          dbaName: row.merchant?.dbaName,
          relationshipCode: row.relationship?.relationshipCode,
          relationshipName: !isEmpty(row.relationship?.relationshipName)
            ? formatRelationshipName(row.relationship, { location })
            : null,
          amount: formatNumber(row.amount, { currency: true }),
          type: snakeToTitle(row.type || ''),
          depositDate: row.depositDate,
          bankName: snakeToTitle(row.bankName || ''),
          bankRouting: row.bankRouting,
          'Bank Account (Last 4)': row.bankAccountLastFour
        }))
      : [];
  }
  if (achTypeCamel === 'reservePayouts') {
    return !isEmpty(data)
      ? data.map((row) => ({
          reservePayoutId: row.reservePayoutId,
          mid: formatMid(row.mid, { location }),
          releaseAmount: row.releaseAmount,
          autoreleaseRollingDays: row.autoreleaseRollingDays,
          isAutorelease: boolToString(row.isAutorelease)
        }))
      : [];
  }
  return achTypeCamel === 'achRejects'
    ? (data ?? []).map((row) => ({
        ...row,
        mid: formatMid(row.mid, { location })
      }))
    : data;
};

const formatRelationshipName = (relationship, options) => {
  const { location } = options || {};
  const { relationshipName, relationshipId } = relationship || {};
  return location === 'portal' || isEmpty(relationshipId) ? (
    relationshipName
  ) : (
    <a href={`/relationship?id=${relationshipId}`}>{relationshipName}</a>
  );
};

export default achDetailsTemplate;
